
.ant-tree {
  height: 100%;
}

#first-panel-arrow {
  position: absolute;
  bottom: 20px;
  z-index: 10000;
  font-size: 20px;
  left: 15px;
  transition: 0.5s all ease;
  color: black;
}

.master-first-showing #first-panel-arrow {
  left: 0px;
  color: #555;
  transform: rotate(0.5turn);
}

#second-panel-arrow {
  position: absolute;
  bottom: 20px;
  z-index: 9999;
  font-size: 20px;
  left: 20px;
  transition: 0.5s all ease;
  transform: rotate(0.5turn);
  opacity: 0;
}

.master-first-showing #second-panel-arrow {
  left: 180px;
  transform: rotate(0turn);
  opacity: 1;
}

.master-first-showing.master-second-showing #second-panel-arrow {
  left: 180px;
  transform: rotate(0.5turn);
  opacity: 1;
}
