
.pan-section {
  padding-bottom: 1rem;

  .pan-section-title {
    background-color: #eee;
    border-top: 1px solid #ccc;

    margin-bottom: .9rem;

    padding-bottom: .1rem;
    padding-left: .3rem;
  }

  .pan-section-content {

  }

}
@primary-color: #e24357;