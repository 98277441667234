@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Roboto&display=swap");
@import "../node_modules/antd/dist/antd.less";


/****************************
  Global element
*****************************/

html {
  overflow: hidden !important;
}

body {
  font-family: "IBM Plex Sans", sans-serif !important;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/****************************
  Global class
*****************************/
.m-xxs {margin: @margin-xss;}
.m-xs {margin: @margin-xs;}
.m-sm {margin: @margin-sm;}
.m-md {margin: @margin-md;}
.m-lg {margin: @margin-lg;}

.mt-xxs {margin-top: @margin-xss;}
.mt-xs {margin-top: @margin-xs;}
.mt-sm {margin-top: @margin-sm;}
.mt-md {margin-top: @margin-md;}
.mt-lg {margin-top: @margin-lg;}

.ml-xxs {margin-left: @margin-xss;}
.ml-xs {margin-left: @margin-xs;}
.ml-sm {margin-left: @margin-sm;}
.ml-md {margin-left: @margin-md;}
.ml-lg {margin-left: @margin-lg;}

.mb-xxs {margin-bottom: @margin-xss;}
.mb-xs {margin-bottom: @margin-xs;}
.mb-sm {margin-bottom: @margin-sm;}
.mb-md {margin-bottom: @margin-md;}
.mb-lg {margin-bottom: @margin-lg;}

.p-xxs {padding: @margin-xss;}
.p-xs {padding: @margin-xs;}
.p-sm {padding: @margin-sm;}
.p-md {padding: @margin-md;}
.p-lg {padding: @margin-lg;}

.pl-xxs {padding-left: @margin-xss;}
.pl-xs {padding-left: @margin-xs;}
.pl-sm {padding-left: @margin-sm;}
.pl-md {padding-left: @margin-md;}
.pl-lg {padding-left: @margin-lg;}

.text-gray {color: #bfbfbf;}
.text-center {text-align: center;}
.fullwidth { width: 100%;}

/****************************
  Ant Design classes
*****************************/

.ant-descriptions-item-container .ant-descriptions-item-content {
  padding-left: @padding-sm;
  padding-right: @padding-sm;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #757d7666;
}

.ant-tree {
  background: unset !important;
}

.ant-tree-show-line .ant-tree-switcher {
  background-color: #f9f9f9;
}

.ant-tree-show-line .ant-tree-indent-unit {
  width: 14px;
}

.ant-select {
  width: 150px;
  min-width: 150px;
}

.ant-tabs {
  overflow: auto;
}

.ant-upload-list {
  display: inline-block;
}



@primary-color: #e24357;