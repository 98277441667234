

.floor {
  user-select: none;

  .floor-toolbar {
  }

  .floor-viewport {
    position: relative;
    display: block;
    min-width: 600px;
    max-width: 100%;
    min-height: 400px;
    overflow: scroll;
    border: 1px solid rgb(215, 192, 170);
    background: rgb(249, 240, 231);
    user-select: none;

    .floor-canvas {
      position: relative;
      display: block;
      margin: 10px;
      user-select: none;

      .floor-background {
        position: absolute;
        z-index: 100;
        user-select: none;
      }

      .floor-desk {
        position: absolute;
        z-index: 200;
        padding: 0;
        margin: 0;
        border: 0;
        user-select: none;

        .desk-icon {
          margin-top: -20%;
          width: 100%;
          height: 120%;
        }
      }
    }
  }
}
