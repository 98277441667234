.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  height: 45px !important;
  overflow: hidden;
}

.ant-picker-calendar-header {
  padding: 0 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0px !important;
}

#monthweek {
  margin-left: 20px;
}

#monthweek button {
  height: 28px;
  width: 80px;
}

/* #monthweek .ant-switch {
  background-color: #1890ff;
} */

#monthweek .ant-switch-handle {
  top: 5px;
}

.welcome-message {
  text-align: center;
  font-weight: bold;
}