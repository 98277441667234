#master-first {
  position: relative;
  transition: 0.5s left ease;
  z-index: 20;
  left: -200px;
  width: 0px;

  overflow-y: auto;
  overflow-x: hidden ;
}

.master-first-showing #master-first {
  left: 0px;
  width: 200px;
}

#master-second {
  z-index: 10;
  left: -420px;
  flex-shrink: 0;
  position: relative;
  transition: 0.5s left ease;
  width: 200px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: content-box;
  /* width: 0px; */
  background-color: #f9f9f9;

  overflow-y: auto;
  overflow-x: hidden ;
}

.master-first-showing #master-second {
  left: -216px;
}

.master-first-showing.master-second-showing #master-second {
  left: 0px;
  width: 200px;
}

#master-third {
  padding-left: 0px;
  /* transition: 1s padding-left 0.5s !important; */
}

.master-first-showing.master-second-showing #master-third {
  padding-left: 432px;
}

.master-first-showing #master-third {
  padding-left: 220px;
}

.master-settings #master-third {
  padding-left: 270px !important;
  margin-left: 0px !important;
  overflow: hidden !important;
}
