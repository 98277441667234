.image-upload-root {
    display: flex;
    border-radius: 20px;

}

.user-profile-img {
    width: 100%;
}

.user-profile-picture {
    align-items: center;
    background-color: #ccc;
    border: 1px solid #aaa;
    border-radius: 62px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 120px;
    margin-bottom: 15px;
}

@primary-color: #e24357;