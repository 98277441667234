

  .my-booking-row-name {

    background-color: #f9f9f9;
    border-bottom: 0px solid;
    border-top: 0px solid;
    box-sizing: border-box;
    grid-column-start: 1;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-top: 10px;
    position: sticky;
    top: 0px;
    align-items: center;
    display: flex;
    justify-content: left;
  }



@primary-color: #e24357;