
.pic-name {
  display: flex;
  align-items: center;

  .img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid rgb(170, 170, 170);
    border-radius: 32px;
    height: 32px;
    width: 32px;
    margin-right: .5rem;

    img {
      width: 100%;
      height: 100%;
    }
  }


}
@primary-color: #e24357;