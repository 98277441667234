.column-integration {
    >.ant-card {
        margin: 15px;    
    }
}

.integration-title {
    margin-bottom: 0px;
    font-size: 1rem;
}

.integration-description {
    font-size: 0.7rem;
    color: grey;
}

.integration-button {
    width: 150px;
}

.read-document {
    margin-left: 15px;
}

.disconnect-button {
    >.ant-btn-link {
        padding: 0px;
        margin-left: 15px;
    }
}

.disconnect-button-text {
    color: gray;
    text-decoration: underline;
}


.warning-token-expired {
    color: grey;
    font-size: 0.7rem;
}

.integration-card {
    height: 100%;
    &>.ant-card-body {
        display: flex;
        height: 100%
    }
}

.send-it-on-block {
    display: flex;
}
@primary-color: #e24357;