@import "../floor.less";

.floor.floor-editor {

  .floor-toolbar {
    padding-top: .2rem ;
    padding-bottom: .2rem ;

    .ant-input-number {
      width: 80px;
    }

    .ant-select {
      width: 150px;
    }
  }

  .floor-viewport {
    height: 70vh;

    .floor-canvas {

      .floor-desk:hover {
          filter: brightness(110%);
          box-shadow: 0 0 2px #565656;
      }

      .floor-selection {
        position: absolute;
        z-index: 500;
        border: 2px dashed #e24357;
        cursor: move;

        .floor-handler-wrap {
          position: relative;
          width: 100%;
          height: 100%;

          .floor-handler-topright {
            position: absolute;
            background-color: #e24357;
            height: 10px;
            width: 10px;
            top: -5px;
            right: -5px;
            cursor: nesw-resize;
          }
        }
      }
    }
  }
}

@primary-color: #e24357;