#topbar {
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  height: 60px;
  left: 0px;
  padding-left: 100px;
  position: absolute;
  top: 0px;
  width: 100vw;
  z-index: 1000;
}

#date-controls {
  align-items: center;
  display: flex;
  margin-left: 10px;
}

#days-controls {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  left: 570px;
  /* padding-right: 20px; */
  position: absolute;
  right: 38px;
}

.topbar-day:hover {
  background-color: #e4e4e4;
}

.topbar-day-viewed {
  border: 1px solid #555;
}
