.day-block {
  align-items: center;
  display: flex;
  justify-content: center;
  border-left: 1px solid #e5e5e5;
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.non-booked-block {
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.non-booked-block > button {
  display: none;
  #background-color: #e5e5e5;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.non-booked-block:hover > button {
  display: flex;
}

.non-booked-block:hover .ant-btn {
  display: inline-block;
}

/* BOOKED BLOCK */

.booked-block {
  position: relative;
  height: 100%;
  width: 100%;
}

.booked-block-content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.booked-block-content .block-delete-icon {
  font-size: 15px;
  margin-left: 4px;
  right: 0px;
}

.block-delete-icon {
  cursor: pointer;
  opacity: 0.5;
  position: absolute;
  right: 4px;
  top: 1px;
}

.block-delete-icon:hover {
  opacity: 1;
}

.block-delete-office {
  opacity: 0.8;
}

.booked-block-content .locate-me {
  font-size: 15px;
  margin-right: 4px;
  position: absolute;
  right: 0px;
}

.booking-main-icon {
  color: white;
  display: flex;
  height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  width: 100%;
}

.booked-block-label {
  color: black;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.block-floor-name {
  color: white;
  cursor: pointer;
  text-decoration: underline;
}
