#booking-bar {
  background-color: white;
  display: grid;
  grid-template-columns: 0px 1fr 1fr 1fr 1fr 1fr;
  left: -50px;
  padding-left: 50px;
  /* padding-right: 20px; */
  position: absolute;
  right: 0px;
  top: 55px;
  box-shadow: rgba(204, 204, 204, 0.78) 0px 1px 6px;
  border: 1px solid #ccc;
  height: 42px;
  z-index: 1000;
}

.booking-bar-transition-enter {
  opacity: 0;
  transform: translate(0px, -20px);
}

.booking-bar-transition-enter-active {
  opacity: 1;
  transform: translate(0px, 0px);
  transition: 200ms;
}

.booking-bar-transition-exit {
  opacity: 1;
}

.booking-bar-transition-exit-active {
  opacity: 0;
  transition: 200ms;
  transform: translate(0px, -20px);
}

.booking-bar-transition-exit-done {
  opacity: 0;
  transition: 200ms;
}
@primary-color: #e24357;