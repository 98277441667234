
.people-tree {

  // Disable selected behavior
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: unset;

    .collaborator-handler {
      text-decoration: underline;
      text-decoration-color: #e24357;
    }

    .people-tag {

      .ant-tag {
        border: 1px solid #e24357;
      }
    }
  }
}

@primary-color: #e24357;