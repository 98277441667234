.calendar-icon {
    font-size: 24px;
    color: #0454a0;
}

.datetime {
    color: gray
}

.header-filter-input {
    & > .ant-picker {
        margin: 15px;
    }
}

.root-chart {
    height: 50%
}

.fullwidth {
    width: 100%;
}

.select-width {
    width: 100%;
}

.tooltip-icon {
    margin-bottom: 0.5em;
}

.justify-content-center {
    justify-content: center;
}

.text-align-center {
    text-align: center;
}

.label {
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: underline;
}

.flex {
    display: flex;
}

.flex-direction-column {
    flex-direction: column;
}

.flex-align-items-center {
    align-items: center;
}

.chart-options {
    display: flex;
    width: 80%;
    padding: 25px;
}

.chart {
    width: 100%;
    padding: 50px;
}
@primary-color: #e24357;