
#main-search::placeholder {
  color: #3c493f !important;
}

#floorplan-menu-icon {
  padding: 0px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

.company-wide {
  cursor: pointer;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  border-radius: 2px;
  display: inline-block;
  margin-bottom: 10px;
}

.company-wide-selected {
  background-color: #757d7666;
}

.ant-menu-item-selected {
  background-color: #d4d1d5 !important;
  color: black !important;
}

.ant-menu-item-selected .anticon {
  color: black !important;
}

.ant-menu-submenu-open {
  color: #FFF !important;
}

#spinner {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1100;
  font-size: 20px;
}


/* ############################
      Main layout
 ##############################*/

.content {
  position: absolute;
  display: flex;
  bottom: 10px;
  left: 100px;
  right: 10px;
  top: 10px;
  overflow: auto;

  &.with-topbar {
    top: 70px;
  }

  .ant-tabs {
    width: 100%;
    overflow: auto;
  }
}
@primary-color: #e24357;