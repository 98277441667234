@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

.hero {
  height: 100vh;
  margin-bottom: 0;
  background-color: #f8faff;
  background-image: url(https://uploads-ssl.webflow.com/5edb52e8800a233cd76a1505/5edb52e8accb0be0d9be6dc4_Pattern.svg);
  background-position: 50% 130%;
  background-size: 1810px;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  #tagline {
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  #title {
    width: 291px;
    height: 100px;
    background: url("logo-text.png");
  }

  .auth-msg {
    text-align: center;
  }
}

.login-wrapper {
  padding-top: 20px;
}

@primary-color: #e24357;