@import "../floor.less";

.floor.floor-viewer {

  .floor-toolbar {
    position: absolute;
    z-index: 400;
    padding: 0.5rem ;
    bottom: 45px;
  }

  .floor-viewport {
    height: 85vh;

    .floor-desk {
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
      }

      &.not-bookable{
        cursor: unset;

        &.drag-over {
          background-color: unset;
        }
      }

      &.disabled {
        cursor: unset;

        &.drag-over {
          background-color: unset;
        }
      }
    }

    .floor-booking {
      position: absolute;
      z-index: 300;
      display: flex;
      align-items: center;
      justify-content: center;


      .avatar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar-img {
          display: flex;
          align-items: center;
          border: 1px solid black;
          justify-content: center;
          overflow: hidden;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }

          &.draggable {
            cursor: move;
          }
        }

        .avatar-close {
          display: none;
          position: absolute;
          right: -4px;
          top: -7px;
          cursor: pointer;
          font-size: 16px;
          z-index: 1000;
        }

        &:hover {
          .avatar-close{
            display: block;
          }
        }
      }


      &.highlighted .avatar-img {
        border-color:  #e24357;
        border-width: 2px;
      }
    }
  }
}

@primary-color: #e24357;